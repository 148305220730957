<template lang="pug">
	div
		h1(class="title") Where do you manufacture your products In-House?
		div(class="columns is-centered")            
			div(class="column is-half")
				div(class="box" v-if="org.manufactureLocation.inHouse === 'businessAddress'")
					h3 {{org.address.street}}
					p {{org.address.city}}, {{org.address.state}} {{org.address.zipcode}}
				div(class="box" v-if="org.manufactureLocation.inHouse === 'shipTo'")
					h3 {{org.shippingAddress.street}}
					p {{org.shippingAddress.city}}, {{org.shippingAddress.state}} {{org.shippingAddress.zipcode}}
				div(class="box" v-if="org.manufactureLocation.inHouse === 'other' || org.manufactureLocation.inHouse === '' || org.manufactureLocation.inHouse === undefined")
					h3 eg: 123 Some St. Suite 301
					p Your City, Your state, 12345
				div(class="block")
					b-field
						b-radio(v-model="org.manufactureLocation.inHouse" native-value="businessAddress")
							strong Business Address
					b-field
						b-radio(v-model="org.manufactureLocation.inHouse" native-value="shipTo")
							strong Ship To Address
					b-field
						b-radio(v-model="org.manufactureLocation.inHouse" native-value="other")
							strong Other
				b-button(type="is-primary-light is-rounded" @click="backStep")
					strong Back
				b-button(type="is-primary is-rounded" @click="nextStep")
					strong Next
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';

const db = firebase.firestore();

export default {
  name: 'BusinessMfgInHouse',
  props: ['org', 'user', 'stepComplete'],
  methods: {
    nextStep() {
      this.save();
      if (this.org.manufactureLocation.inHouse === 'businessAddress') {
        this.stepComplete();
      } else if (this.org.manufactureLocation.inHouse === 'shipTo') {
        this.$store.commit('SET_STEP', 'mfgLocation');
      } else if (this.org.manufactureLocation.inHouse === 'shipTo') {
        this.$store.commit('SET_STEP', 'mfgOther');
      } else if (this.org.manufactureLocation.inHouse === 'other') {
        console.log(this.org.manufactureLocation.inHouse);
        this.$store.commit('SET_STEP', 'mfgOther');
      }
    },
    backStep() {
      this.state.commit('SET_STEP', 'mfgQuestion');
    },
    save() {
      db.collection('organizations')
        .doc(this.user.data.organizationId)
        .update({
          manufactureLocation: {
            inHouse: this.org.manufactureLocation.inHouse,
          },
        })
        .then(() => {})
        .catch(() => {
          this.$buefy.toast.open({
            message: 'There was a problem saving your organization.',
            type: 'is-danger',
            duration: 5000,
          });
        });
    },
  },
};
</script>
