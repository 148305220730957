<template lang="pug">
  section(class="hero")
    div(class="hero-body")
      div(class="columns is-centered")
        div(class="column is-one-quarter-desktop")
          h1(class="title") Login
          div(v-if="error" class="alert alert-danger") {{error}}
          b-field
            b-input(type="email" placeholder="Email" v-model="email" rounded)
          b-field
            b-input(type="password" placeholder="Password" v-model="password" rounded)
          b-field
            p(class="control")
              b-button(@click="submit" type="is-primary is-rounded" :disabled="!email.length || !password.length" ) 
                strong Log in
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';

export default {  
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      error: null,
    };
  },
  methods: {
    submit() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.$router.replace({ name: "Products" });
        })
        .catch(() => {
          this.$buefy.toast.open({
          message: 'Invalid username or password',
          type: 'is-danger',
          duration: 5000
        });
      })
    }
  }
}
</script>
