<template lang="pug">
  div
    h1(class="title") Business Details
    figure
      img(src='@/assets/images/setupBusiness.png')
    b-button(type="is-primary is-rounded" @click="nextStep") 
      strong Continue
</template>

<script>
export default {
  name: 'BusinessIntro',
  props: ['step'],
  methods: {
    nextStep() {
      this.$store.commit('SET_STEP', 'name');
    },
  },
};
</script>

<style scoped>
img {
  height: 300px;
}
</style>
