<template lang="pug">
  footer
    div(class="container")
      div(class="content has-text-centered")
        p &#169; {{ new Date().getFullYear() }} Vukoo CPG&#174;.  All rights reserved.  Trademarks are owned by Vukoo IP LLC or used with permission.
</template>

<script>
export default {
  name: 'Copyright',
};
</script>
