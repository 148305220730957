<template lang="pug">
	div
		h1(class="title") Do you currently pay a fixed rate for storage?
		div(class="columns is-centered")
			div(class="column is-half")
				b-field
					b-radio(v-model="fixedRate" name="fixedRate" native-value='true') Yes
				b-field
					b-radio(v-model="fixedRate" name='fixedRate' native-value='false') No
				b-button(type="is-primary-light is-rounded" @click="backStep")
					strong Back
				b-button(type="is-primary is-rounded" @click="nextStep")
					strong Next
</template>

<script>
export default {
  name: 'InventoryStorageType',
  data() {
    return {
      fixedRate: 'true',
    };
  },
  methods: {
    nextStep() {
      if (this.fixedRate === 'true') {
        this.$store.commit('SET_STEP', 'inventoryFixedRate');
      } else {
        this.$store.commit('SET_STEP', 'perPallet');
      }
    },
    backStep() {
      this.$store.commit('SET_STEP', 'inventoryExists');
    },
  },
};
</script>
