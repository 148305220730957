<template lang="pug">
  div(class="mx-6")
    section(class="hero is-light")
      section(class="level")
        div(class="hero-body")
          h1(class="title") Ingredients
        div(class="level-right")
          .titleButton
            b-button(type="is-primary is-rounded" @click="$router.push('IngredientGuide')") 
              strong Add Ingredient
    br
    div(v-if="loading")
    div(v-else-if="!loading && !ingredients.length")
      div(class="hero has-text-centered")
        div(class="hero-body")
          h1(class="title") No Ingredients
          h1(class="subtitle") Add the ingredients that make up your products!
    div(v-else)
      b-table(class="box" default-sort="name" :data='ingredients')
        b-table-column(field="name" label="Name" sortable width="25%" v-slot="props") {{ props.row.name }}
        b-table-column(field="prices" label="Price" sortable v-slot="props") ${{ props.row.prices[0].price }} per {{ props.row.prices[0].quantity }} {{ props.row.prices[0].unit }}
        b-table-column(field="action" cellClass="has-text-right" v-slot="props")
          b-button(type="is-primary is-rounded ml-1" title="Edit" icon-left="edit" @click="$router.push({ name: 'IngredientGuide', params: {inputIngredient: props.row }})")
          b-button(type="is-danger is-rounded ml-1" title="Delete" icon-left="trash" @click="deleteIngredientConfirmation(props.row)")
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';
import { mapGetters } from "vuex";

const db = firebase.firestore();

export default {
  name: 'Ingredients',
  computed: {
    ...mapGetters({
      user: "user",
    })
  },
  watch: {
    'user': {
      handler (user) {
        if (user.data.organizationId) { 
          this.getIngredients();
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      ingredients: [],
      loading: true
    }
  },
  methods: {
    getIngredients() {
      db.collection("ingredients")
        .where("organizationId", "==", this.user.data.organizationId)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            let ingredient = doc.data()
            ingredient.id = doc.id
            this.ingredients.push(ingredient)
          })
          this.loading = false
        })
        .catch(() => {
        this.$buefy.toast.open({
        message: 'There was a problem getting ingredients.',
        type: 'is-danger',
        duration: 5000});
        this.loading = false
      });
    },
    deleteIngredientConfirmation(ingredient) {
      this.$buefy.dialog.confirm({
        title: 'Deleting Ingredient',
        message: 'Are you sure you want to delete <b>' + ingredient.name + "</b>?",
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteIngredient(ingredient)
      })
    },
    deleteIngredient(ingredient) {
      db.collection("ingredients")
        .doc(ingredient.id)
        .delete()
        .then(() => {
          this.ingredients = this.ingredients.filter(item => item !== ingredient)
          this.$buefy.toast.open({
            message: 'Ingredient Deleted',
            type: 'is-success',
            duration: 5000});
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'There was a problem deleting the ingredient.',
          type: 'is-danger',
          duration: 5000});
      });
    },
  }
}
</script>

<style lang="scss">
.titleButton {
  padding: 25px
}
</style>