<template lang="pug">
  section(class="hero")
    div(class="hero-body")
      div(class="columns is-centered")
        div(class="column is-half")
          h1(class="title") Vukoo CPG is in private beta
          h1(class="subtitle") Want in? Enter your email below and we'll let you know when it's ready!
          b-field
            b-input(placeholder="Name" v-model="name" rounded)
          b-field
            b-input(placeholder="Company Website" v-model="website" rounded)
          b-field
            b-input(placeholder="Email" v-model="email" rounded)
          b-field
            p(class="control")
              b-button(@click="submit" type="is-primary is-rounded" :disabled="!name.length || !website.length || !email.length") 
                strong Submit

</template>

<script>
import * as fb from '../firebase'

export default {
  name: 'Register',
  data() {
    return {
      name: '',
      website: '',
      email: '',
    };
  },
  methods: {
    submit() {
      fb.emailSignupsRepo.doc().set({
        name: this.name,
        website: this.website,
        email: this.email
      });
      this.name = '';
      this.website = '';
      this.email = '';
      this.$buefy.toast.open({
          message: 'Thanks! We will let you know when it is ready!',
          type: 'is-success',
          duration: 5000
      });
    }
  }
}
</script>