<template lang="pug">
  b-navbar
    template(slot="brand")
      b-navbar-item(@click="$router.push('/').catch(()=>{})")
        img(
          src="@/assets/images/vukoo.png"
          alt="Vukoo CPG")
      b-navbar-item
        h1(class="title")
          a(@click="$router.push('/').catch(()=>{})")
            strong Vukoo CPG
    template(slot="start" v-if="user.loggedIn") 
      b-navbar-item(tag="router-link" :to="{ path: '/products' }")
        b-icon(icon="rocket")
        span.ml-md Products
      b-navbar-item(tag="router-link" :to="{ path: '/ingredients' }")
        b-icon(icon="seedling")
        span.ml-md Ingredients
      b-navbar-item(tag="router-link" :to="{ path: '/packaging' }")
        b-icon(icon="box-open")
        span.ml-md Packaging
      b-navbar-item(tag="router-link" :to="{ path: '/vendors' }")
        b-icon(icon="store")
        span.ml-md Vendors
    template(slot="end" v-if="user.loggedIn")
      b-navbar-dropdown(href="/profile" :label="user.data.displayName || 'Profile'")
        b-navbar-item(@click="$router.push('profile').catch(()=>{})") Profile
        b-navbar-item(@click="$router.push('organization').catch(()=>{})") Organization
        b-navbar-item(@click="$router.push('setupLanding').catch(()=>{})") Setup Guide
        div(class="navbar-divider")
        div
          b-navbar-item(@click="signOut")
            strong Logout
    template(slot="end" v-else)
      div(class="buttons")
        b-navbar-item(class="button is-primary is-rounded" href="/register")
          strong Sign up
        b-navbar-item(class="button is-light is-rounded" href="/login")
          strong Log in
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import { mapGetters } from 'vuex';

export default {
  name: 'Navbar',
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({
            name: 'Landing',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ml-md {
  margin-left: 0.5rem;
}
</style>
