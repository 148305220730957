<template lang="pug">
  section(class="has-text-centered")
    div(class="container")
      div(v-if="step === 'intro'")
        InventoryIntro
      div(v-if="step === 'inventoryExists'")
        InventoryExists(:stepComplete='emitStepComplete')
      div(v-if="step === 'storageType'")
        InventoryStorageType
      div(v-if="step === 'inventoryFixedRate'")
        InventoryFixedRate(:stepComplete='emitStepComplete')
      div(v-if="step === 'perPallet'")
        InventoryPerPallet(:org='organization', :user='user')
      div(v-if="step === 'palletFees'")
        InventoryPalletFees(:org='organization', :user='user')
      div(v-if="step === 'storageLocation'")
        InventoryStorageLocation(:org='organization', :user='user')
</template>

<script>
import { mapGetters } from 'vuex';

import InventoryIntro from './InventoryIntro.vue';
import InventoryExists from './InventoryExists.vue';
import InventoryStorageType from './InventoryStorageType.vue';
import InventoryFixedRate from './InventoryFixedRate.vue';
import InventoryPerPallet from './InventoryPerPallet.vue';
import InventoryPalletFees from './InventoryPalletFees.vue';

export default {
  name: 'SetupInventory',
  components: {
    InventoryIntro,
    InventoryExists,
    InventoryStorageType,
    InventoryFixedRate,
    InventoryPerPallet,
    InventoryPalletFees,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      organization: 'organization',
      step: 'step',
    }),
  },
  methods: {
    emitStepComplete() {
      this.$store.commit('SET_STEP', 'intro');
      this.$emit('step-complete');
    },
  },
};
</script>
