<template lang="pug">
	div
		h1(class="title") Inventory
		figure
			img(src='@/assets/images/setupInventory.png')
		b-button(type="is-primary is-rounded" @click="nextStep") 
			strong Continue
</template>

<script>
export default {
  name: 'InventoryIntro',
  methods: {
    nextStep() {
      this.$store.commit('SET_STEP', 'inventoryExists');
    },
  },
};
</script>

<style scoped>
img {
  height: 300px;
}
</style>
