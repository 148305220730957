<template lang="pug">
	div
		h1(class="title is-danger") You will need to enter your monthly storage fee in Fixed Costs.
		div(class="columns is-centered")
			div(class="column is-half")
				b-button(type="is-primary-light is-rounded" @click="backStep")
					strong Back
				b-button(type="is-primary is-rounded" @click="nextStep")
					strong Next
</template>

<script>
export default {
  name: 'InventoryFixedRate',
  props: ['stepComplete'],
  methods: {
    nextStep() {
      this.stepComplete();
    },
    backStep() {
      this.$store.commit('SET_STEP', 'storageType');
    },
  },
};
</script>
