<template lang="pug">
  div
    div(class="columns is-centered")
      div(class="column is-one-quarter")
        div(class="box has-text-centered")
          h1(class="title") Home page here
</template>

<script>

export default {
  name: 'Home',
}
</script>
