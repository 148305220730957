<template lang="pug">
	div
		h1(class="title") Confirm Your in-house manufacturing address
		div(class="columns is-centered")
			div(class="column is-half")
				b-field(label="Street Address")
					b-input(placeholder="Street Address" type="text" v-model="org.manufactureLocation.address.street" rounded)
				b-field(label="City")
					b-input(placeholder="City" type="text" v-model="org.manufactureLocation.address.city" rounded)
				b-field(label="State")
					b-select(placeholder="State" v-model="org.manufactureLocation.address.state" expanded rounded)
						option(v-for="state in states") {{ state.name }}
				b-field(label="Zip Code")
					b-input(placeholder="Zip Code" v-model="org.shippingAddress.zipcode" rounded)
				b-button(type="is-primary-light is-rounded" @click="backStep") 
					strong Back
				b-button(type="is-primary is-rounded" @click="nextStep") 
					strong Next
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';

import states from '../../../constants/states';

const db = firebase.firestore();

export default {
  name: 'BusinessMfgOther',
  props: ['org', 'user', 'stepComplete'],
  data() {
    return {
      states: states,
    };
  },
  methods: {
    nextStep() {
      this.save();
      this.stepComplete();
    },
    backStep() {
      this.$state.commit('SET_STEP', 'mfgQuestion');
    },
    save() {
      db.collection('organizations')
        .doc(this.user.data.organizationId)
        .update({
          manufactureLocation: {
            address: this.org.manufactureLocation.address,
          },
        })
        .then(() => {})
        .catch(() => {
          this.$buefy.toast.open({
            message: 'There was a problem saving your Manufacture location',
            type: 'is-danger',
            duration: 5000,
          });
        });
    },
  },
};
</script>
