<template lang="pug">
  section(class="has-text-centered m-5")
    div(class="has-text-centered has-background-white")
      h1(class="title" v-if="inputVendor") Edit Vendor
      h1(class="title" v-else) Add a Vendor
      div(class="columns is-centered")
        div(class="column is-half")
          div(class="box is-shadowless p-0")
            h1(class="subtitle has-text-left") VENDOR
            b-field(label="NAME")
              b-input(placeholder="Vendor Name" v-model="vendor.name" rounded)
            b-field(label="ADDRESS 1")
              b-input(placeholder="Address 1" v-model="vendor.address.address1" rounded)
            b-field(label="ADDRESS 2")
              b-input(placeholder="Address 2" v-model="vendor.address.address2" rounded)
            div(class="columns mb-0")
              div(class="column")
                b-field(label="CITY")
                  b-input(placeholder="City" v-model="vendor.address.city" rounded)
                b-field(label="ZIP CODE")
                  b-input(placeholder="Zip Code" v-model="vendor.address.zipCode" rounded)
              div(class="column")
                b-field(label="STATE")
                  b-select(placeholder="State" v-model="vendor.address.state" expanded rounded)
                    option(v-for="state in states") {{ state.name }}
                b-field(label="COUNTRY")
                  b-input(placeholder="Country" v-model="vendor.address.country" rounded)
            b-field(label="WEBSITE")
              b-input(placeholder="Website" v-model="vendor.website" rounded)
          div(class="box is-shadowless p-0")
            h1(class="subtitle has-text-left") CONTACT
            div(class="columns mb-0")
              div(class="column")
                b-field(label="FIRST NAME")
                  b-input(placeholder="First Name" v-model="vendor.contact.firstName" rounded)
              div(class="column")
                b-field(label="LAST NAME")
                  b-input(placeholder="Last Name" v-model="vendor.contact.lastName" rounded)
            b-field(label="EMAIL")
              b-input(placeholder="Email" v-model="vendor.contact.email" rounded)
            div(class="columns mb-0")
              div(class="column")
                b-field(label="PHONE")
                  b-input(placeholder="Phone" v-model="vendor.contact.phone" rounded)
              div(class="column")
                b-field(label="FAX")
                  b-input(placeholder="Fax" v-model="vendor.contact.fax" rounded)
          b-button(type="is-primary is-rounded" @click="saveVendor") 
            strong(v-if="inputVendor") Save
            strong(v-else) Add
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';
import { mapGetters } from "vuex";
import states from '../../constants/states'

const db = firebase.firestore();

export default {
  name: 'modifyVendor',
  props: {
    inputVendor: {
      type: Object
    },
    fromModal: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
    })
  },
  data() {
    return {
      states: states,
      vendor: {
        states,
        name: '',
        address: {
          address1: '',
          address2: '',
          city: '',
          state: '',
          zipCode: '',
          country: '',
        },
        website: '',
        contact: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          fax: '',
        }
      }
    }
  },
  mounted() {
    if (this.inputVendor != null) {
      this.vendor = { ...this.inputVendor }
      if (this.vendor.address == null) {
        this.vendor.address = {}
      }
      if (this.vendor.contact == null) {
        this.vendor.contact = {}
      }
    }
  },
  methods: {
    saveVendor() {
      if(this.inputVendor == null) {
        this.addVendor();
      } else {
        this.editVendor();
      }
    },
    addVendor() {
      this.vendor.organizationId = this.user.data.organizationId
      db.collection("vendors").add({
            ...this.vendor
        })
        .then(() => {
          this.$buefy.toast.open({
          message: 'Vendor Added',
          type: 'is-success',
          duration: 5000});
          if(this.fromModal) {
            this.$emit('vendor-added')
            this.$parent.close();
          } else {
            this.$router.replace({ name: "Vendors" });
          }
        })
        .catch(() => {
          this.$buefy.toast.open({
          message: 'There was a problem saving the vendor.',
          type: 'is-danger',
          duration: 5000});
        });
    },
    editVendor() {
      db.collection("vendors").doc(this.vendor.id).set({
        ...this.vendor
      })
      .then(() => {
        this.$buefy.toast.open({
        message: 'Vendor Saved',
        type: 'is-success',
        duration: 5000});
        this.$router.replace({ name: "Vendors" });
      })
      .catch(() => {
        this.$buefy.toast.open({
        message: 'There was a problem saving the vendor.',
        type: 'is-danger',
        duration: 5000});
      });
    }
  }
}
</script>

<style lang="scss">
.titleButton {
  padding: 25px
}
</style>