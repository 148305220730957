<template lang="pug">
  div
    section(class="hero")
      div(class="hero-body")
        div(class="container has-text-centered")
          h1(class="title") Organization
    div(class="columns is-centered")
      div(class="column is-half-tablet is-one-third-fullhd")
        b-field
          b-input(placeholder="Organization Name", v-model="organization.name", rounded, icon="building")
        b-field
        b-field
          b-input(placeholder="Street Address", v-model="organization.address.street", rounded, icon="map-marker-alt")
        b-field
          b-input(placeholder="City", v-model="organization.address.city", rounded)
        b-field
          b-input(placeholder="State", v-model="organization.address.state", rounded)
        b-field
          b-input(placeholder="Zip Code", type="number", v-model="organization.address.zipcode", rounded)
        b-field
          b-input(placeholder="Phone Number", type="phone", v-model="organization.phone", rounded, icon="phone")
        b-field(grouped position="is-right")
          p(class="control")
            b-button(@click="save" type="is-primary is-rounded") 
              strong Save
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';
import { mapGetters } from 'vuex';

const db = firebase.firestore();

export default {
  name: 'Profile',
  computed: {
    ...mapGetters({
      user: 'user',
      organization: 'organization',
    }),
  },
  methods: {
    save() {
      db.collection('organizations')
        .doc(this.user.data.organizationId)
        .set({
          name: this.organization.name,
          address: this.organization.address,
          phone: this.organization.phone,
        })
        .then(() => {
          this.$buefy.toast.open({
            message: 'Organization updated',
            type: 'is-success',
            duration: 5000,
          });
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: 'There was a problem saving your organization.',
            type: 'is-danger',
            duration: 5000,
          });
        });
    },
  },
};
</script>
