<template lang="pug">
  div
    h1(class="title") Enter the fees you currently pay for pallet storage.
    h2(class="subtitle") i.e. finished inventory, raw ingredients, packaging supplies stored
    div(class="columns is-centered")
      div(class="column")
        div(class="column is-one-thrid")
          h1(class="title is-4") AMBIENT
          h4(class="subtitle is-6") i.e. stored at room temperature
          b-field(label="Price($) Per Pallet")
            b-input(placeholder="Price ($)" v-model='org.inventory.pallet.ambient.price' rounded)
      div(class="column is-centered")
        div(class="column is-one-thrid")
          h1(class="title is-4") REFRIDGERATED
          h4(class="subtitle is-6") i.e. stored chilled
          b-field(label="Price($) Per Pallet")
            b-input(placeholder="Price ($)" v-model='org.inventory.pallet.refrigerated.price' rounded)
      div(class="column is-centered")
        div(class="column is-one-thrid")
          h1(class="title is-4") FROZEN
          h4(class="subtitle is-6") i.e. stored in freezer
          b-field(label="Price($) Per Pallet")
            b-input(placeholder="Price ($)" v-model='org.inventory.pallet.frozen.price' rounded)
    div(class="column is-one-quarter")
      b-field(label="Frequency")
        b-select(placeholder="Frequency" v-model="org.inventory.pallet.frequency" expanded rounded)
          option(v-for="frequency in frequencies") {{ frequency.name }}
    b-button(type="is-primary-light is-rounded" @click="backStep")
      strong Back
    b-button(type="is-primary is-rounded" @click="nextStep")
      strong Next
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';
import frequency from '../../../constants/frequency';

const db = firebase.firestore();

export default {
  name: 'InventoryPalletFees',
  props: ['org', 'user'],
  data() {
    return {
      frequencies: frequency,
    };
  },
  methods: {
    nextStep() {
      this.save();
      if (this.org.inventory.storageFeeType === 'pallet') {
        this.$store.commit('SET_STEP', 'storageLocation');
      } else {
        this.$store.commit('SET_STEP', 'warehouseFees');
      }
    },
    backStep() {
      this.$store.commit('SET_STEP', 'perPallet');
    },
    save() {
      db.collection('organizations')
        .doc(this.user.data.organizationId)
        .update({
          inventory: {
            pallet: this.org.inventory.pallet,
          },
        })
        .then(() => {})
        .catch((err) => {
          console.log('PalletFeeError', err);
          this.$buefy.toast.open({
            message: 'There was a problem saving your per pallet fees',
            type: 'is-danger',
            duration: 5000,
          });
        });
    },
  },
};
</script>
