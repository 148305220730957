// mass converts to grams
// volume converts to liter

const ingredientUnits =  [
  { 
    name: "teaspoon",
    abbreviation: "tsp",
    type: "volume",
    conversion: 0.00492892
  },
  { 
    name: "tablespoon" ,
    abbreviation: "Tbsp",
    type: "volume",
    conversion: 0.0147868
  },
  { 
    name: "ounce",
    abbreviation: "oz",
    type: "mass",
    conversion: 28.3495
  },
  { 
    name: "cup",
    abbreviation: "c", 
    type: "volume",
    conversion: 0.236588
  },
  { 
    name: "pint",
    abbreviation: "pt", 
    type: "volume",
    conversion: 0.473176
  },
  { 
    name: "quart",
    abbreviation: "qt", 
    type: "volume",
    conversion: 0.946353
  },
  { 
    name: "pound",
    abbreviation: "lb", 
    type: "mass",
    conversion: 453.59237
  },
  { 
    name: "gallon",
    abbreviation: "gal", 
    type: "volume",
    conversion: 3.78541
  },
  { 
    name: "milliliter",
    abbreviation: "mL", 
    type: "volume",
    conversion: 0.001
  },
  { 
    name: "liter",
    abbreviation: "L", 
    type: "volume",
    conversion: 1
  },
  { 
    name: "kilogram",
    abbreviation: "kg", 
    type: "mass",
    conversion: 1000,
  },
  { 
    name: "gram",
    abbreviation: "gm", 
    type: "mass",
    conversion: 1,
  },
  { 
    name: "drum",
    abbreviation: "", 
    type: "volume",
    conversion: 189.271
  },
]

export default ingredientUnits