import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Home from '../views/Home.vue'
import Profile from '../views/Profile.vue'
import Organization from '../views/Organization.vue'
import Setup from '../views/setup/Setup.vue'
import SetupLanding from '../views/setup/SetupLanding.vue'
import Landing from '../views/Landing.vue'
import Product from '../views/product/Product.vue'
import Products from '../views/product/Products.vue'
import ManageProduct from '../views/product/ManageProduct.vue'
import ProductLines from '../views/product/ProductLines.vue'
import ProductLine from '../views/product/ProductLine.vue'
import ManageProductLine from '../views/product/ManageProductLine.vue'
import Ingredients from '../views/ingredient/Ingredients.vue'
import AddIngredient from '../views/ingredient/AddIngredient.vue'
import IngredientGuide from '../views/ingredient/IngredientGuide.vue'
import EditIngredient from '../views/ingredient/EditIngredient.vue'
import Packaging from '../views/packaging/Packaging.vue'
import PackagingGuide from '../views/packaging/PackagingGuide.vue'
import Vendors from '../views/vendor/Vendors.vue'
import ManageVendor from '../views/vendor/ManageVendor.vue'
import SupplyChain from '../views/SupplyChain.vue'
import { auth } from '../firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/organization',
    name: 'Organization',
    component: Organization,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/setupLanding',
    name: 'SetupLanding',
    component: SetupLanding,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/setup',
    name: 'Setup',
    component: Setup,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/product',
    name: 'Product',
    props: true,
    component: Product,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/manageProduct',
    name: 'ManageProducts',
    props: true,
    component: ManageProduct,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/productLine/:id',
    name: 'ProductLine',
    props: true,
    component: ProductLine,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/productLines',
    name: 'ProductLines',
    component: ProductLines,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/manageProductLine',
    name: 'ManageProductLine',
    props: true,
    component: ManageProductLine,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ingredients',
    name: 'Ingredients',
    component: Ingredients,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/addIngredient',
    name: 'AddIngredient',
    component: AddIngredient,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/IngredientGuide',
    name: 'IngredientGuide',
    props: true,
    component: IngredientGuide,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/editIngredient',
    name: 'EditIngredient',
    props: true,
    component: EditIngredient,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/packaging',
    name: 'Packaging',
    component: Packaging,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/packagingGuide',
    name: 'PackagingGuide',
    props: true,
    component: PackagingGuide,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/vendors',
    name: 'Vendors',
    component: Vendors,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/manageVendor',
    name: 'ManageVendor',
    component: ManageVendor,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/supplyChain',
    name: 'SupplyChain',
    component: SupplyChain,
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
