<template lang="pug">
  section(class="hero")
    div(class="hero-body")
      div(class="columns is-centered has-text-centered")
        div(class="column is-half")
          img(
            src='@/assets/images/dashboard.png')
          h1(class="title") Vukoo CPG is Coming Soon!
          h2(class="subtitle")
            a(href="/register") Sign up to learn more.
</template>

<script>

export default {
  name: 'Landing',
}
</script>