<template lang="pug">
  div
    section(class="hero")
      div(class="hero-body")
        div(class="container has-text-centered")
          h1(class="title") Profile
    div(class="columns is-centered")
      div(class="column is-half-tablet is-one-third-fullhd")
        b-field
          b-input(placeholder="Name" v-model="user.data.displayName" rounded icon="user")
        b-field
          b-input(placeholder="Email" v-model="user.data.email" rounded icon="envelope")
        b-field(grouped position="is-right")
          p(class="control")
            b-button(@click="save" type="is-primary is-rounded") 
              strong Save
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import { mapGetters } from "vuex";

export default {
  name: 'Profile',
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  methods: {
    save() {
      var user = firebase.auth().currentUser;
      user.updateProfile({
        displayName: this.user.data.displayName
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Profile updated',
          type: 'is-success',
          duration: 5000
        });
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'There was a problem saving your profile.',
          type: 'is-danger',
          duration: 5000
        });
      })
    }
  }
}

</script>