<template lang="pug">
  div
    section(class="hero is-light")
      section(class="level")
        div(class="hero-body")
          h1(class="title") Supply Chain
        div(class="level-right")
          .titleButton
            b-button(type="is-info") Add Ingredient
    br
    b-table(class="box" :data='data')
      b-table-column(field="ingredient" label="Ingredient" v-slot="props") {{ props.row.ingredient }}
      b-table-column(field="vendor" label="Vendor" v-slot="props") {{ props.row.vendor }}
      b-table-column(field="cost" label="Cost" v-slot="props") {{ props.row.cost }}
      b-table-column(field="goal-cost" label="Goal Cost" v-slot="props") {{ props.row.goalCost }}
</template>

<script>
export default {
  name: 'SupplyChain',
  data() {
    const data = [
      { 'ingredient': 'Organic All-Purpose Flour','vendor': 'Central Milling', 'cost': '$0.77', 'goalCost': '$0.65' },
      { 'ingredient': 'Baking Soda', 'cost': '$0.40', 'goalCost': '$0.35' },
      { 'ingredient': 'Brown Sugar', 'cost': '$0.98', 'goalCost': '$0.90' },
      { 'ingredient': 'Salt', 'cost': '$0.95', 'goalCost': '$0.90' },
      { 'ingredient': 'Butter', 'cost': '$3.97', 'goalCost': '$3.50' },
      { 'ingredient': 'Eggs', 'cost': '$1.20', 'goalCost': '$1.00' },
      { 'ingredient': 'Bananas', 'cost': '$0.58', 'goalCost': '$0.50' },
    ]
    return {
      data,
    }
  }
}
</script>

<style lang="scss">
.titleButton {
  padding: 25px
}
</style>