<template lang="pug">
  section(class="has-text-centered")
    div(class="container")
      h1(class="title") Edit Ingredient
      div(class="columns is-centered")
        div(class="column is-half")
          b-field
            b-input(placeholder="Ingredient Name" v-model="ingredient.name" rounded)
          b-field
            b-select(placeholder="Vendor" v-model="ingredient.vendor" expanded rounded)
              option(v-for="vendor in vendors"
                    :value="vendor.id"
                    :key="vendor.id") {{ vendor.name }}
          b-field
            b-input(placeholder="Cost" v-model="ingredient.prices[0].price" rounded)
          b-button(type="is-primary is-rounded" @click="editIngredient") 
            strong Save
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';
import { mapGetters } from "vuex";

const db = firebase.firestore();

export default {
  name: 'EditIngredient',
  props: {
    ingredient: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
    })
  },
  data() {
    return {
      vendors: []
    }
  },
  mounted () {
    this.getVendors();
  },
  methods: {
    getVendors() {
      db.collection("vendors")
        .where("organizationId", "==", this.user.data.organizationId)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            let vendor = doc.data()
            vendor.id = doc.id
            this.vendors.push(vendor)
          })
        })
        .catch(() => {
        this.$buefy.toast.open({
        message: 'There was a problem getting vendors.',
        type: 'is-danger',
        duration: 5000});
      });
    },
    editIngredient() {
      db.collection("ingredients").doc(this.ingredient.id).set({
          ...this.ingredient
      })
      .then(() => {
        this.$buefy.toast.open({
        message: 'Ingredient Saved',
        type: 'is-success',
        duration: 5000});
        this.$router.replace({ name: "Ingredients" });
      })
      .catch(() => {
        this.$buefy.toast.open({
        message: 'There was a problem saving the ingredient.',
        type: 'is-danger',
        duration: 5000});
      });
    }
  }
}
</script>

<style lang="scss">
.titleButton {
  padding: 25px
}
</style>