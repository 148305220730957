<template lang="pug">
  section(class="has-text-centered")
    div(class="container")
      div(v-if="step === 'intro'")
        h1(class="title") Supply Chain
        figure
          img(src='@/assets/images/setupSupplyChain.png')
        b-button(type="is-primary is-rounded" @click="continueFromIntro") 
          strong Continue
      div(v-if="step === 'ingredientsIntro'")
        h1(class="title") Ingredients
        h2(class="subtitle") Let's enter all ingredients contained in your products.
        b-button(type="is-primary-light is-rounded" @click="backFromName") 
              strong Back
        b-button(type="is-primary is-rounded" @click="continueIngredientsIntro") 
          strong Next
</template>

<script>
export default {
  name: 'SetupSupplyChain',
  data() {
    return {
      step: 'intro',
    }
  },
   methods: {
    continueFromIntro() {
      this.step = 'ingredientsIntro'
    },
    continueIngredientsIntro() {
      this.step = 'ingredients'
    },
   }
}
</script>

<style scoped>
img {
  height: 300px;
}
</style>