<template lang="pug">
  section(class="has-text-centered")
    div(class="container")
      h1(class="title") Setup Fixed Costs
      figure
        img(src='@/assets/images/setupFixedCosts.png' width="500px" height="auto")
      b-button(type="is-primary is-rounded" @click="$router.push('setupBusinessName')") 
        strong Continue
</template>

<script>
export default {
  name: 'SetupFixedCosts'
}
</script>