<template lang="pug">
  section
    div(class="container")
      h1(v-if="inputProduct" class="title" class="has-text-centered") Edit Product
      h1(v-else class="title" class="has-text-centered") Add a Product
      div(class="columns is-centered")
        div(class="column is-half")
          div(class="box")
            p(class="title is-5") Name
            b-field
              b-input(placeholder="Product Name" v-model="product.name" rounded)
          div(class="box")
            p(class="title is-5") Product Line
            b-field
              b-select(placeholder="Product Line" v-model="product.productLine" expanded rounded)
                option(v-for="productLine in productLines"
                    :value="productLine.id"
                    :key="productLine.id") {{ productLine.name }}
        div(class="column is-half")
          div(class="box")
            div(class="level")
              p(class="level-left title is-5") Recipe
              div(class="level-right")
                b-dropdown(aria-role="list")
                  template(#trigger="{ active }")
                    b-button(icon-left="plus-circle" type="is-primary" rounded) Add
                  b-dropdown-item(
                    aria-role="listitem" 
                    v-for="ingredient of allIngredients" 
                    :key="ingredient.id"
                    @click="addIngredient(ingredient)") {{ingredient.name}}
            b-table(v-if='product.ingredients' :data='product.ingredients' rounded)
              b-table-column(field="name" label="INGREDIENT" v-slot="props") {{ props.row.name }}
              b-table-column(field="quantity" label="QUANTITY" v-slot="props" width="20%") 
                b-field
                  b-input(v-model="props.row.quantity" rounded)
              b-table-column(field="unit" label="UNIT" v-slot="props" width="30%")
                b-field
                  b-select(placeholder="Enter Unit" v-model="props.row.unit" expanded rounded)
                    option(v-for="unit in ingredientUnits") {{ unit.name }}
              b-table-column(field="action" cellClass="has-text-right" v-slot="props")
                b-button(icon-left="minus-circle" type="is-danger" @click="deleteIngredient(props.row)" rounded)
            h1(v-else class="has-text-centered") No Ingredients Added Yet!
      div(class="has-text-centered")
        b-button(v-if="inputProduct" type="is-primary is-rounded" @click="saveProduct") 
          strong Save Product
        b-button(v-else type="is-primary is-rounded" @click="addProduct") 
          strong Add Product
</template>

<script>
import ingredientUnits from '../../constants/ingredientUnits'
import firebase from 'firebase/app';
import 'firebase/firestore';
import { mapGetters } from "vuex";

const db = firebase.firestore();

export default {
  name: 'ManageProduct',
  props: {
    inputProduct: {
      type: Object
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    })
  },
  data() {
    return {
      ingredientUnits: ingredientUnits,
      product: {
        name: '',
        ingredients: [],
      },
      allIngredients: [],
      productLines: [],
    }
  },
  async mounted () {
    await this.getIngredients();
    this.getProductLines();
    this.initalizeProduct();
  },
  methods: {
    initalizeProduct() {
      if (this.inputProduct != null) {
        this.product = { ...this.inputProduct }
        this.product.ingredients.forEach(ingredient => {
          const foundIngredient = this.allIngredients.find(i => i.id === ingredient.id)
          ingredient.name = foundIngredient.name
        })
      }
    },
    addIngredient(ingredient) {
      this.product.ingredients.push({
        id: ingredient.id,
        name: ingredient.name,
        quantity: '',
        unit: '',
      })
    },
    deleteIngredient(ingredient) {
      const index = this.product.ingredients.indexOf(ingredient)
      if (index > -1) {
        this.product.ingredients.splice(index, 1);
      }
    },
    addProduct() {
      this.product.organizationId = this.user.data.organizationId
      db.collection("products").add({
          ...this.product
      })
      .then(() => {
        this.$buefy.toast.open({
        message: 'Product Added',
        type: 'is-success',
        duration: 5000});
        this.$router.replace({ name: "Products" });
      })
      .catch(() => {
        this.$buefy.toast.open({
        message: 'There was a problem saving the product.',
        type: 'is-danger',
        duration: 5000});
      });
    },
    saveProduct() {
      db.collection("products").doc(this.product.id).set({
        ...this.product
      })
      .then(() => {
        this.$buefy.toast.open({
        message: 'Product Saved',
        type: 'is-success',
        duration: 5000});
        this.$router.replace({ name: "Products" });
      })
      .catch(() => {
        this.$buefy.toast.open({
        message: 'There was a problem saving the product.',
        type: 'is-danger',
        duration: 5000});
      });
    },
    async getIngredients() {
      await db.collection("ingredients")
        .where("organizationId", "==", this.user.data.organizationId)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            let ingredient = doc.data()
            ingredient.id = doc.id
            this.allIngredients.push(ingredient)
          })
        })
    },
    getProductLines() {
      db.collection("product-lines")
        .where("organizationId", "==", this.user.data.organizationId)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            let productLine = doc.data()
            productLine.id = doc.id
            this.productLines.push(productLine)
          })
        })
    },
  }
}
</script>

<style lang="scss">
.titleButton {
  padding: 25px
}
</style>