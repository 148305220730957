<template lang="pug">
  div(class="mx-6")
    section(class="hero is-light")
      section(class="level")
        div(class="hero-body")
          h1(class="title") Vendors
        div(class="level-right")
          .titleButton
            b-button(type="is-primary is-rounded" @click="$router.push('manageVendor')") 
              strong Add Vendor
    br
    div(v-if="loading")
    div(v-else-if="!loading && !vendors.length")
      div(class="hero has-text-centered")
        div(class="hero-body")
          h1(class="title") No Vendors
          h1(class="subtitle") Who do you buy your ingredients from?
    div(v-else)
      b-table(class="box" default-sort="name" :data='vendors')
        b-table-column(field="name" label="Name" sortable width="25%" v-slot="props") {{ props.row.name }}
        b-table-column(field="action" cellClass="has-text-right" v-slot="props")
          b-button(type="is-primary is-rounded ml-1" title="Edit" icon-left="edit" @click="$router.push({ name: 'ManageVendor', params: {inputVendor: props.row }})")
          b-button(type="is-danger is-rounded ml-1" title="Delete" icon-left="trash" @click="deleteVendorConfirmation(props.row)")
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';
import { mapGetters } from "vuex";

const db = firebase.firestore();

export default {
  name: 'Vendors',
  computed: {
    ...mapGetters({
      user: "user",
    })
  },
  watch: {
    'user': {
      handler (user) {
        if (user.data.organizationId) { 
          this.getVendors();
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      vendors: [],
      loading: true
    }
  },
  methods: {
    getVendors() {
      db.collection("vendors")
        .where("organizationId", "==", this.user.data.organizationId)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            let vendor = doc.data()
            vendor.id = doc.id
            this.vendors.push(vendor)
          })
          this.loading = false
        })
        .catch(() => {
        this.$buefy.toast.open({
        message: 'There was a problem getting vendors.',
        type: 'is-danger',
        duration: 5000});
        this.loading = false
      });
    },
    deleteVendorConfirmation(vendor) {
      this.$buefy.dialog.confirm({
        title: 'Deleting Vendor',
        message: 'Are you sure you want to <b>delete</b> ' + vendor.name + "? This action cannot be undone.",
        confirmText: 'Delete Vendor',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteVendor(vendor)
      })
    },
    deleteVendor(vendor) {
      db.collection("vendors")
        .doc(vendor.id)
        .delete()
        .then(() => {
          this.vendors = this.vendors.filter(item => item !== vendor)
          this.$buefy.toast.open({
            message: 'Vendor Deleted',
            type: 'is-success',
            duration: 5000});
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'There was a problem deleting the vendor.',
          type: 'is-danger',
          duration: 5000});
      });
    },
  }
}
</script>

<style lang="scss">
.titleButton {
  padding: 25px
}
</style>