<template lang="pug">
	div
		h1(class="title") Do you manufacture your products in-house or outsource to a co-manufacturer?
		div(class="columns is-centered")
			div(class="column is-half")
				b-field
					b-radio(v-model="org.manufactureLocation.type" name="type" native-value="inHouse")
						strong In-house
						span &nbsp;(i.e. self produce)
				b-field
					b-radio(v-model="org.manufactureLocation.type" name="type" native-value="coMan")
						strong Co-Manufacturer
						span &nbsp;(i.e. outsource to another manufacturer)
				b-field
					b-radio(v-model="org.manufactureLocation.type" name="type" native-value="both")
						strong Both
						span &nbsp;(i.e. self produce and outsource)
				b-button(type="is-primary-light is-rounded" @click="backStep")
					strong Back
				b-button(type="is-primary is-rounded" @click="nextStep")
					strong Next
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';

const db = firebase.firestore();

export default {
  name: 'BusinessMfgQuestion',
  props: ['org', 'user'],
  methods: {
    nextStep() {
      if (this.org.manufactureLocation.type === 'inHouse') {
        this.$store.commit('SET_STEP', 'mfgInHouse');
      } else {
        this.$store.commit('SET_STEP', 'mfgCoMan');
      }
    },
    backStep() {
      this.$store.commit('SET_STEP', 'tin');
    },
    save() {
      db.collection('organizations')
        .doc(this.user.data.organizationId)
        .update({
          manufactureLocation: {
            type: this.org.manufactureLocation.type,
          },
        })
        .then(() => {})
        .catch(() => {
          this.$buefy.toast.open({
            message: 'There was a problem saving your Manufacture type',
            type: 'is-danger',
            duration: 5000,
          });
        });
    },
  },
};
</script>
