<template lang="pug">
	div
		h1(class="title") What is the name of your CPG Business?
		h2(class="subtitle") i.e. legal business name
		div(class="columns is-centered")
			div(class="column is-half")
				b-field
					b-input(placeholder="Business Name" v-model="org.name" rounded)
				b-button(type="is-primary-light is-rounded" @click="backStep") 
					strong Back
				b-button(type="is-primary is-rounded" @click="nextStep") 
					strong Next
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';

const db = firebase.firestore();

export default {
  name: 'BusinessName',
  props: ['org', 'user'],
  methods: {
    nextStep() {
      this.$store.commit('SET_STEP', 'address');
    },
    backStep() {
      this.$store.commit('SET_STEP', 'intro');
    },
    saveBusinessName() {
      db.collection('organizations')
        .doc(this.user.data.organizationId)
        .update({
          name: this.organization.name,
        })
        .then(() => {})
        .catch(() => {
          this.$buefy.toast.open({
            message: 'There was a problem saving the name.',
            type: 'is-danger',
            duration: 5000,
          });
        });
    },
  },
};
</script>
