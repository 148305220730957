<template lang="pug">
	div
		h1(class="title") Enter shipping address
		h2(class="subtitle") i.e. the address you recieve raw ingredients, supplies etc.
		div(class="columns is-centered")
			div(class="column is-half")
				b-field(label="Street Address")
					b-input(placeholder="Street Address" type="text" v-model="org.shippingAddress.street" rounded)
				b-field(label="City")
					b-input(placeholder="City" type="text" v-model="org.shippingAddress.city" rounded)
				b-field(label="State")
					b-select(placeholder="State" v-model="org.shippingAddress.state" expanded rounded)
						option(v-for="state in states") {{ state.name }}
				b-field(label="Zip Code")
					b-input(placeholder="Zip Code" v-model="org.shippingAddress.zipcode" rounded)

				h4(class="title is-4")
					strong Is this address your co-manufacturer?
				h6(class="subtitle") i.e. outsourced product manufacturing
				b-field
					//- value must be of type string, Boolean true returns empty string 
					b-radio(v-model="org.shippingAddress.coMan" native-value='true') Yes
				b-field
					b-radio(v-model="org.shippingAddress.coMan" native-value='false') No
				b-button(type="is-primary-light is-rounded" @click="backStep") 
					strong Back
				b-button(type="is-primary is-rounded" @click="nextStep") 
					strong Next
</template>

<script>
import states from '../../../constants/states';
import firebase from 'firebase/app';
import 'firebase/firestore';

const db = firebase.firestore();

export default {
  name: 'BusinessShippingAddress',
  props: ['org', 'user'],
  data() {
    return {
      states: states,
    };
  },
  methods: {
    nextStep() {
      this.saveShippingAddress();
      this.$store.commit('SET_STEP', 'ein');
    },
    backStep() {
      this.$store.commit('SET_STEP', 'address');
    },
    saveShippingAddress() {
      db.collection('organizations')
        .doc(this.user.data.organizationId)
        .update({
          shippingAddress: this.org.shippingAddress,
        })
        .then(() => {})
        .catch(() => {
          this.$buefy.toast.open({
            message: 'There was a problem saving the address.',
            type: 'is-danger',
            duration: 5000,
          });
        });
    },
  },
};
</script>
