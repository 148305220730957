// prettier-ignore
const portals = {
  'Alabama': 'https://myalabamataxes.alabama.gov/_/',
  'Alaska': 'http://www.tax.alaska.gov/programs/programs/index.aspx?10002',
  'Arizona': 'https://azdor.gov/forms/tpt-forms/arizona-resale-certificate',
  'Arkansas': 'https://atap.arkansas.gov/_/#7',
  'California': 'https://onlineservices.cdtfa.ca.gov/_/#1',
  'Colorado': 'https://mybiz.colorado.gov/intro',
  'Connecticut': 'https://portal.ct.gov/drs-myconnect',
  'Delaware':
    'https://revenue.delaware.gov/business-tax-forms/exemption-certificates/',
  'District of Columbia': 'https://mytax.dc.gov/_/',
  'Florida': 'https://floridarevenue.com/taxes/eservices/Pages/registration.aspx',
  'Georgia': 'https://gtc.dor.ga.gov/_/',
  'Hawaii': 'https://hbe.ehawaii.gov/BizEx/home.eb',
  'Idaho': 'https://business.idaho.gov/business-wizard/?view=section-1',
  'Illinois': 'https://mytax.illinois.gov/_/',
  'Indiana': 'https://intime.dor.in.gov/eServices/_/',
  'Iowa': 'https://tax.iowa.gov/forms/iowa-salesuseexcise-tax-exemption-certificate-31-014',
  'Kansas': 'https://www.kdor.ks.gov/apps/kcsc/login.aspx',
  'Kentucky': 'https://revenue.ky.gov/Pages/index.aspx',
  'Louisiana': 'https://latap.revenue.louisiana.gov/_/',
  'Maine': 'https://www.maine.gov/revenue/taxes/sales-use-service-provider-tax',
  'Maryland':
    'https://interactive.marylandtaxes.gov/webapps/comptrollercra/entrance.asp',
  'Massachusetts': 'https://www.mass.gov/lists/dor-sales-and-use-tax-forms',
  'Michigan': 'https://mto.treasury.michigan.gov/eai/mtologin/authenticate?URL=/',
  'Minnesota': 'https://www.mndor.state.mn.us/tp/eservices/_/',
  'Mississippi': 'https://tap.dor.ms.gov/_/',
  'Missouri': 'https://dor.mo.gov/register-business/',
  'Montana':
    'https://mtrevenue.gov/publications/montana-business-registry-resale-certification-from-resale/',
  'Nebraska':
    'https://revenue.nebraska.gov/businesses/starting-business-nebraska',
  'Nevada': 'https://tax.nv.gov/Forms/General_Purpose_Forms/',
  'New Hampshire':
    'https://www.revenue.nh.gov/assistance/resale-exempt-certs.htm',
  'New Jersey': 'https://www.state.nj.us/treasury/taxation/br1.shtml',
  'New Mexico': 'https://tap.state.nm.us/TAP/_/',
  'New York':
    'https://www.tax.ny.gov/pubs_and_bulls/tg_bulletins/st/how_to_register_for_nys_sales_tax.htm',
  'North Carolina':
    'https://www.ncdor.gov/taxes-forms/sales-and-use-tax/sales-and-use-tax-registration',
  'North Dakota':
    'https://irs.ein-federal-tax-id.com/IRS/application/?gclid=Cj0KCQjw06OTBhC_ARIsAAU1yOV_pOWv_p0MrfjEUyq0_YY3ZQ-fXdik_CCbIoW7_UGY0-q6JqVn4wEaAiG8EALw_wcB',
  'Ohio': 'https://tax.ohio.gov/business/ohio-business-taxes/sales-and-use/registration',
  'Oklahoma': 'https://oktap.tax.ok.gov/oktap/web/_/',
  'Oregon': 'https://www.oregon.gov/dor/pages/sales-tax.aspx',
  'Pennsylvania': 'https://www.pa100.state.pa.us',
  'Rhode Island': 'https://www.ri.gov/taxation/BAR/',
  'South Carolina': 'https://dor.sc.gov/tax/registration/general-information',
  'South Dakota': 'https://dor.sd.gov/businesses/taxes/sales-use-tax/',
  'Tennessee':
    'https://www.tn.gov/revenue/taxes/sales-and-use-tax/registration.html',
  'Texas': 'https://comptroller.texas.gov/taxes/permit/',
  'Utah': 'https://tap.tax.utah.gov/TaxExpress/_/',
  'Vermont':
    'https://tax.vermont.gov/business-and-corp/sales-and-use-tax/getting-started',
  'Virginia': 'https://www.tax.virginia.gov/forms',
  'Washington':
    'https://dor.wa.gov/taxes-rates/retail-sales-tax/reseller-permits',
  'West Virginia':
    'https://tax.wv.gov/Business/BusinessRegistration/Pages/BusinessRegistration.aspx',
  'Wisconsin': 'https://www.revenue.wi.gov/Pages/FAQS/pcs-seller.aspx',
  'Wyoming': 'https://www.tax-id-bureau.com/wyoming-resale-certificate/',
};

export default portals;
