<template lang="pug">
  .app
    navbar
    .app-content
      router-view
    copyright
</template>

<script>
import 'firebase/auth';
import Navbar from '@/components/navbar/Navbar.vue'
import Copyright from '@/components/footer/Copyright.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Copyright,
  }
}
</script>

<style lang="scss">
.app {
  padding: 25px;
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  .app-content {
    flex: 1;
  }
}
</style>

