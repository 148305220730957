<template lang="pug">
	div
		h1(class="title") Enter your business EIN number.
		h2(class="subtitle") i.e. IRS employer identification number
		div(class="columns is-centered")
			div(class="column is-half")
				b-field(v-if="!noEin" label="Business EIN Number")
					b-input(placeholder="xx-xxxxxxx" v-model="org.ein" rounded)
				b-field
					b-checkbox(v-model="noEin") I don't have one.
				b-button(type="is-primary-light is-rounded" @click="backStep") 
					strong Back
				b-button(type="is-primary is-rounded" @click="nextStep") 
					strong Next
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';

const db = firebase.firestore();

export default {
  name: 'BusinessEin',
  props: ['org', 'user'],
  data() {
    return {
      noEin: false,
    };
  },
  methods: {
    nextStep() {
      if (!this.noEin) {
        this.saveEin();
        this.$store.commit('SET_STEP', 'tin');
      } else {
        this.$store.commit('SET_STEP', 'irsLink');
      }
    },
    backStep() {
      this.$store.commit('SET_STEP', 'address');
    },
    saveEin() {
      db.collection('organizations')
        .doc(this.user.data.organizationId)
        .update({
          ein: this.org.ein,
        })
        .then(() => {})
        .catch(() => {
          this.$buefy.toast.open({
            message: 'There was a problem saving your EIN',
            type: 'is-danger',
            duration: 5000,
          });
        });
    },
  },
};
</script>
