<template lang="pug">
  ModifyVendor(v-bind:inputVendor="inputVendor")
</template>

<script>
import ModifyVendor from '@/components/vendor/ModifyVendor.vue'

export default {
  name: 'ManageVendor',
  components: {
    ModifyVendor
  },
  props: {
    inputVendor: {
      type: Object
    }
  }
}
</script>