<template lang="pug">
  section(class="hero")
    div(class="hero-body has-text-centered")
      div(class="container")
        b-steps(v-model="stepIndex" vertical type="is-info" :has-navigation="false" :animated="false")
          b-step-item(step="1" label="Business Details" :clickable="true")
            SetupBusiness(v-on:step-complete="stepIndex += 1")
          b-step-item(step="2" label="Inventory" :clickable="true")
            SetupInventory(v-on:step-complete="stepIndex += 1")
          b-step-item(step="3" label="Supply Chain" :clickable="true")
            SetupSupplyChain
          b-step-item(step="4" label="Products" :clickable="true")
            SetupProducts
          b-step-item(step="5" label="Fixed Costs" :clickable="true")
            SetupFixedCosts
          b-step-item(step="6" label="Sales Channels" :clickable="true")
            SetupBusiness
          b-step-item(step="7" label="Settings" :clickable="true")
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import { mapGetters } from 'vuex';
import SetupBusiness from '@/components/setup/Business/SetupBusiness.vue';
import SetupInventory from '@/components/setup/Inventory/SetupInventory.vue';
import SetupSupplyChain from '@/components/setup/SetupSupplyChain.vue';
import SetupProducts from '@/components/setup/SetupProducts.vue';
import SetupFixedCosts from '@/components/setup/SetupFixedCosts.vue';

export default {
  name: 'Setup',
  components: {
    SetupBusiness,
    SetupInventory,
    SetupSupplyChain,
    SetupProducts,
    SetupFixedCosts,
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
  data() {
    return {
      stepIndex: 0,
    };
  },
  methods: {
    save() {
      var user = firebase.auth().currentUser;
      user
        .updateProfile({
          displayName: this.user.data.displayName,
        })
        .then(() => {
          this.$buefy.toast.open({
            message: 'Profile updated',
            type: 'is-success',
            duration: 5000,
          });
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: 'There was a problem saving your profile.',
            type: 'is-danger',
            duration: 5000,
          });
        });
    },
  },
};
</script>
