<template lang="pug">
  section(class="has-text-centered")
    div(class="container")
      div(v-if="step === 'intro'")
        BusinessIntro
      div(v-if="step === 'name'")
        BusinessName(:org='organization', :user='user')
      div(v-if="step === 'address'")
        BusinessAddress(:org='organization', :user='user')
      div(v-if="step === 'shipToAddress'")
        BusinessShippingAddress(:org='organization', :user='user')
      div(v-if="step === 'ein'")
        BusinessEin(:org='organization', :user='user')
      div(v-if="step === 'irsLink'")
        BusinessIRSLink
      div(v-if="step === 'tin'")
        BusinessTin(:org='organization', :user='user')
      div(v-if="step === 'stateLink'")
        BusinessTinLink(:org='organization')
      div(v-if="step === 'mfgQuestion'")
        BusinessMfgQuestion(:org='organization', :user='user')
      div(v-if="step === 'mfgLocation'")
        BusinessMfgLocation(:org='organization', :stepComplete='emitStepComplete', :user='user')
      div(v-if="step === 'mfgInHouse'")
        BusinessMfgInHouse(:org='organization', :stepComplete='emitStepComplete', :user='user')
      div(v-if="step === 'mfgOther'")
        BusinessMfgOther(:org='organization', :stepComplete='emitStepComplete', :user='user')
</template>

<script>
import { mapGetters } from 'vuex';

import BusinessIntro from './BusinessIntro.vue';
import BusinessName from './BusinessName.vue';
import BusinessAddress from './BusinessAddress.vue';
import BusinessShippingAddress from './BusinessShippingAddress.vue';
import BusinessEin from './BusinessEin.vue';
import BusinessIRSLink from './BusinessIRSLink.vue';
import BusinessTin from './BusinessTin.vue';
import BusinessTinLink from './BusinessTinLink.vue';
import BusinessMfgQuestion from './BusinessMfgQuestion.vue';
import BusinessMfgLocation from './BusinessMfgLocation.vue';
import BusinessMfgInHouse from './BusinessMfgInHouse.vue';
import BusinessMfgOther from './BusinessMfgOther.vue';

export default {
  name: 'SetupBusiness',
  components: {
    BusinessIntro,
    BusinessName,
    BusinessAddress,
    BusinessShippingAddress,
    BusinessEin,
    BusinessIRSLink,
    BusinessTin,
    BusinessTinLink,
    BusinessMfgQuestion,
    BusinessMfgLocation,
    BusinessMfgInHouse,
    BusinessMfgOther,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      organization: 'organization',
      step: 'step',
    }),
  },
  methods: {
    emitStepComplete() {
      this.$store.commit('SET_STEP', 'intro');
      this.$emit('step-complete');
    },
  },
};
</script>
