<template lang="pug">
	div
		h1(class="title") Is this fee per pallet or warehouse?
		div(class="columns is-centered")
			div(class="column is-half")
				b-field
					b-radio(v-model="feeType" name="feeType" native-value='pallet') Pallet
				b-field
					b-radio(v-model="feeType" name='feeType' native-value='warehouse') Warehouse
				b-field
					b-radio(v-model="feeType" name='feeType' native-value='both') Both
				b-button(type="is-primary-light is-rounded" @click="backStep")
					strong Back
				b-button(type="is-primary is-rounded" @click="nextStep")
					strong Next
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';

const db = firebase.firestore();

export default {
  name: 'InventoryPerPallet',
  props: ['org', 'user'],
  data() {
    return {
      feeType: 'pallet',
    };
  },
  methods: {
    nextStep() {
      this.save();
      if (this.feeType === 'warehouse') {
        this.$store.commit('SET_STEP', 'warehouseFees');
      } else {
        this.$store.commit('SET_STEP', 'palletFees');
      }
    },
    backStep() {
      this.$store.commit('SET_STEP', 'storageType');
    },
    save() {
      db.collection('organizations')
        .doc(this.user.data.organizationId)
        .update({
          inventory: {
            storageFeeType: this.feeType,
            pallet: {
              ambient: {
                price: '',
              },
              refrigerated: {
                price: '',
              },
              frozen: {
                price: '',
              },
              frequency: '',
            },
          },
        })
        .then(() => {})
        .catch(() => {
          this.$buefy.toast.open({
            message: 'There was an error saving your progress',
            type: 'is-danger',
            duration: 5000,
          });
        });
    },
  },
};
</script>
