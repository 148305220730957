import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyB1uSpDHW2-xJHRjf9BL-5T0AmUFpVyH4g",
    authDomain: "kuvoo-fa140.firebaseapp.com",
    databaseURL: "https://kuvoo-fa140.firebaseio.com",
    projectId: "kuvoo-fa140",
    storageBucket: "kuvoo-fa140.appspot.com",
    messagingSenderId: "9738908019",
    appId: "1:9738908019:web:19a778c144e590918bd506",
    measurementId: "G-TBJ72QMQQL"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore()
const auth = firebase.auth()

const emailSignupsRepo = db.collection('email-signups')

export {
    db,
    auth,
    emailSignupsRepo
}