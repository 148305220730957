<template lang="pug">
	div
		h1(class="title") Enter your tax resale license number.
		h2(class="subtitle") i.e. State tax resale number to purchase wholesale raw ingredients, supplies
		div(class="columns is-centered")
			div(class="column is-half")
				b-field(v-if="!noTin" label="Business Tax Resale License Number")
					b-input(placeholder="xxxxxxxx" v-model="org.tin" rounded)
				b-field
					b-checkbox(v-model="noTin") I don't have one.
				b-button(type="is-primary-light is-rounded" @click="backStep") 
					strong Back
				b-button(type="is-primary is-rounded" @click="nextStep") 
					strong Next
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';

const db = firebase.firestore();

export default {
  name: 'BusinessTin',
  props: ['org', 'user'],
  data() {
    return {
      noTin: false,
    };
  },
  methods: {
    nextStep() {
      if (!this.noTin && !!this.org.tin) {
        this.saveTin();
        this.$store.commit('SET_STEP', 'mfgQuestion');
      } else {
        this.$store.commit('SET_STEP', 'stateLink');
      }
    },
    backStep() {
      this.$store.commit('SET_STEP', 'ein');
    },
    saveTin() {
      db.collection('organizations')
        .doc(this.user.data.organizationId)
        .update({
          tin: this.organization.tin,
        })
        .then(() => {})
        .catch(() => {
          this.$buefy.toast.open({
            message: 'There was a problem saving your EIN',
            type: 'is-danger',
            duration: 5000,
          });
        });
    },
  },
};
</script>
