<template lang="pug">
  section(class="has-text-centered")
    div(class="container")
      h1(class="title" v-if="inputProductLine") Edit Product Line
      h1(class="title" v-else) Add Product Line
      div(class="columns is-centered")
        div(class="column is-half")
          b-field
            b-input(placeholder="Product Line Name" v-model="productLine.name" rounded)
          b-button(type="is-primary is-rounded" @click="saveProductLine") 
            strong Save
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';
import { mapGetters } from "vuex";

const db = firebase.firestore();

export default {
  name: 'ManageProductLine',
  props: {
    inputProductLine: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
    })
  },
  data() {
    return {
      productLine: {
        organizationId: '',
        name: ''
      }
    }
  },
  mounted () {
    this.initalizeProductLine();
  },
  methods: {
    initalizeProductLine() {
      if (this.inputProductLine != null) {
        this.productLine = { ...this.inputProductLine }
      }
    },
    saveProductLine() {
      this.productLine.organizationId = this.user.data.organizationId

      if(this.inputProductLine == null) {
        db.collection("product-lines").add({
          ...this.productLine
        })
        .then(() => {
          this.$buefy.toast.open({
          message: 'Product Line Added',
          type: 'is-success',
          duration: 5000});
          this.$router.replace({ name: "ProductLines" });
        })
        .catch(() => {
          this.$buefy.toast.open({
          message: 'There was a problem saving the product line.',
          type: 'is-danger',
          duration: 5000});
        });
      } else {
        db.collection("product-lines").doc(this.productLine.id).set({
          ...this.productLine
        })
        .then(() => {
          this.$buefy.toast.open({
          message: 'Product Line Saved',
          type: 'is-success',
          duration: 5000});
          this.$router.replace({ name: "ProductLines" });
        })
        .catch(() => {
          this.$buefy.toast.open({
          message: 'There was a problem saving the product line.',
          type: 'is-danger',
          duration: 5000});
        });
      }
    },
  }
}
</script>