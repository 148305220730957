<template lang="pug">
	div
		h1(class="title") The next step is to apply for an EIN
		h2(class="subtitle") This link will take you to the IRS website.
		h3(class="subtitle is-6") You will need to complete this before using the app.
		div(class="columns is-centered")
			div(class="column is-half")
				div(class="block")
					a(class="button is-primary is-rounded is-medium" 
						href="https://www.irs.gov/businesses/small-businesses-self-employed/apply-for-an-employer-identification-number-ein-online" 
						target="_blank" 
						rel="noopener noreferrer") Apply for your EIN
				b-button(type="is-primary-light is-rounded" @click="backStep") 
					strong Back
				b-button(type="is-primary is-rounded" @click="nextStep") 
					strong Next
</template>

<script>
export default {
  name: 'BusinessIRSLink',
  methods: {
    nextStep() {
      this.$store.commit('SET_STEP', 'tin');
    },
    backStep() {
      this.$store.commit('SET_STEP', 'address');
    },
  },
};
</script>
