<template lang="pug">
  div(class="mx-6")
    section(class="hero is-light")
      section(class="level")
        div(class="hero-body")
          h1(class="title") Product Lines
        div(class="level-right")
          .titleButton
            b-button(type="is-primary is-rounded" @click="$router.push('manageProductLine')") 
              strong Add Product Line
    br
    div(v-if="loading")
    div(v-else-if="!loading && !productLines.length")
      div(class="hero has-text-centered")
        div(class="hero-body")
          h1(class="title") No Product Lines
          h1(class="subtitle") Add product lines to organize your products!
    div(v-else)
      b-table(class="box" :data='productLines' default-sort="name" sort-icon-size="is-small")
        b-table-column(field="name" label="Name" sortable v-slot="props" width="25%") {{ props.row.name }}
        b-table-column(field="action" cellClass="has-text-right" v-slot="props")
          b-button(type="is-info is-rounded ml-1" title="Edit" icon-left="eye" @click="$router.push({ name: 'ProductLine', params: {id: props.row.id }})")
          b-button(type="is-primary is-rounded ml-1" title="Edit" icon-left="edit" @click="$router.push({ name: 'ManageProductLine', params: {inputProductLine: props.row }})")
          b-button(type="is-danger is-rounded ml-1" title="Delete" icon-left="trash" @click="deleteProductConfirmation(props.row)")
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';
import { mapGetters } from "vuex";

const db = firebase.firestore();

export default {
  name: 'ProductLines',
  computed: {
    ...mapGetters({
      user: "user",
    })
  },
  watch: {
    'user': {
      handler (user) {
        if (user.data.organizationId) { 
          this.getProductLines();
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      productLines: [],
      products: [],
      loading: true
    }
  },
  methods: {
    getProductLines() {
      db.collection("product-lines")
        .where("organizationId", "==", this.user.data.organizationId)
        .get()
        .then(querySnapshot => {
          this.products = []
          querySnapshot.forEach(doc => {
            let productLine = doc.data()
            productLine.id = doc.id
            this.productLines.push(productLine)
          })
          this.loading = false
        })
        .catch(() => {
        this.$buefy.toast.open({
        message: 'There was a problem getting product lines.',
        type: 'is-danger',
        duration: 5000});
        this.loading = false
      });
    },
    deleteProductConfirmation(productLine) {
      let message = 'Are you sure you want to delete <b>' + productLine.name + "</b>? This action cannot be undone."
      this.$buefy.dialog.confirm({
        title: 'Deleting Product Line',
        message: message,
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteProductLine(productLine)
      })
    },
    deleteProductLine(productLine) {
      db.collection("product-lines")
        .doc(productLine.id)
        .delete()
        .then(() => {
          this.productLines = this.productLines.filter(item => item !== productLine)
          this.$buefy.toast.open({
            message: 'Product Line Deleted',
            type: 'is-success',
            duration: 5000});
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'There was a problem deleting the product line.',
          type: 'is-danger',
          duration: 5000});
      });
    }
  }
}
</script>

<style lang="scss">
.titleButton {
  padding: 25px
}
</style>