<template lang="pug">
  section(class="hero is-large")
    div(class="hero-body has-text-centered")
      div(class="container")
        h1(class="title") Welcome to Vukoo CPG
        h2(class="subtitle") A software designed to simplify your time and business
        b-button(type="is-primary is-rounded" @click="$router.push('setup')") 
          strong Let's Begin
</template>

<script>
export default {
  name: 'SetupLanding'
}

</script>