<template lang="pug">
  div(class="mx-6")
    section(class="hero is-light")
      section(class="level")
        div(class="hero-body")
          h1(class="title") Product Line - {{this.productLine.name}}
        div(class="level-right")
          .titleButton
            b-button(type="is-rounded") 
              router-link(:to="{ name: 'ProductLines' }")
                strong Back
    br
    div(class="columns is-centered has-text-centered")
      div(class="column is-half box")
        h1(class="title") Products
        h1(v-if="!products.length" class="subtitle") No products associated with this product line
        h1(v-else v-for="product in products" class="subtitle") {{product.name}}
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';
import { mapGetters } from "vuex";

const db = firebase.firestore();

export default {
  name: 'ProductLine',
  props: {
    id: {
      type: String
    }
  },
  mounted () {
    this.getProductLine();
  },
  computed: {
    ...mapGetters({
      user: "user",
    })
  },
  data() {
    return {
      productLine: {},
      products: [],
      loading: true
    }
  },
  methods: {
    getProductLine() {
      db.collection("product-lines")
        .doc(this.id)
        .get()
        .then(doc => {
          if(doc.exists) {
            this.productLine = doc.data()
            this.getProducts()
          }
          this.loading = false
        })
        .catch(() => {
        this.$buefy.toast.open({
        message: 'There was a problem getting product line.',
        type: 'is-danger',
        duration: 5000});
        this.loading = false
      });
    },
    getProducts() {
      db.collection("products")
        .where("organizationId", "==", this.user.data.organizationId)
        .where("productLine", "==", this.id)
        .get()
        .then(querySnapshot => {
          this.products = []
          querySnapshot.forEach(doc => {
            let product = doc.data()
            product.id = doc.id
            this.products.push(product)
          })
          this.loading = false
        })
        .catch(() => {
        this.$buefy.toast.open({
        message: 'There was a problem getting products.',
        type: 'is-danger',
        duration: 5000});
      });
    },
    deleteProductConfirmation(productLine) {
      this.$buefy.dialog.confirm({
        title: 'Deleting Product Line',
        message: 'Are you sure you want to delete <b>' + productLine.name + "</b>? This action cannot be undone.",
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteProductLine(productLine)
      })
    }
  }
}
</script>

<style lang="scss">
.titleButton {
  padding: 25px
}
</style>