import Vue from 'vue';
import Vuex from 'vuex';
import firebase from 'firebase/app';
import 'firebase/firestore';

Vue.use(Vuex);

const db = firebase.firestore();

const store = new Vuex.Store({
  state: {
    step: 'intro',
    user: {
      loggedIn: false,
      data: null,
    },
    organization: {
      basicInfo: {
        name: '',
        ein: '',
        tin: '',
        phone: '',
      },
      addresses: {
        business: {
          street1: '',
          street2: '',
          city: '',
          state: '',
          zipcode: '',
          isShipTo: 'true',
        },
        shipping: {
          street1: '',
          street2: '',
          city: '',
          state: '',
          zipcode: '',
          isCoMan: 'false',
        },
        coMan: {
          street1: '',
          street2: '',
          city: '',
          state: '',
          zipcode: '',
          type: 'inHouse',
        },
        palletStorage: {
          street1: '',
          street2: '',
          city: '',
          state: '',
          zipcode: '',
        },
        warehouse: {
          street1: '',
          street2: '',
          city: '',
          state: '',
          zipcode: '',
        },
      },
      inventory: {
        storageFeeType: '',
        pallet: {
          ambient: {
            price: '',
          },
          refridgerated: {
            price: '',
          },
          frozen: {
            price: '',
          },
          frequency: '',
        },
        warehouse: {
          price: '',
          frequency: '',
        },
      },
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    organization(state) {
      return state.organization;
    },
    step(state) {
      return state.step;
    },
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_ORGANIZATION(state, data) {
      state.organization = data;
    },
    SET_STORAGE(state, data) {
      state.storage = data;
    },
    SET_STEP(state, data) {
      state.step = data;
    },
  },
  actions: {
    fetchUser({ commit, dispatch }, user) {
      commit('SET_LOGGED_IN', user !== null);
      if (user) {
        const userRef = db.collection('users').doc(user.uid);
        userRef.get().then((doc) => {
          if (doc.exists) {
            dispatch('fetchOrganization', doc.data().organizationId);
            commit('SET_USER', {
              ...this.state.user.data,
              organizationId: doc.data().organizationId,
            });
          }
        });
        commit('SET_USER', {
          displayName: user.displayName,
          email: user.email,
        });
      } else {
        commit('SET_USER', null);
      }
    },
    fetchOrganization({ commit }, organizationId) {
      const organizationRef = db
        .collection('organizations')
        .doc(organizationId);
      organizationRef.get().then((doc) => {
        if (doc.exists) {
          let organization = doc.data();
          if (!organization.name) {
            organization.name = '';
          }
          if (!organization.address) {
            let address = {};
            address.street = '';
            address.city = '';
            address.state = '';
            address.zipcode = '';
            organization.address = address;
          }
          commit('SET_ORGANIZATION', organization);
        }
      });
    },
    fetchStorage({ commit }, storageId) {
      const storageRef = db.collection('storage').doc(storageId);
      storageRef.get().then((doc) => {
        if (doc.exists) {
          let storage = doc.data();
          if (!storage.type) {
            storage.type = '';
          }
          if (!storage.fee) {
            storage.fee = '';
          }
          if (!storage.frequency) {
            storage.frequency = '';
          }
          commit('SET_STORAGE', storage);
        }
      });
    },
  },
});

export default store;
