<template lang="pug">
  section(class="hero is-large")
    b-modal(v-model="showVendorModal")
      ModifyVendor(v-bind:fromModal="true" v-on:vendor-added="getVendors")
    
    div(class="hero-body has-text-centered")
      div(class="container")

        div(v-if="step === 1")
          div(class="columns is-centered")
            div(class="column is-half")
              h1(class="title") Which packaging are we adding?
              b-field(label="PACKAGING")
                b-input(placeholder="Enter Packaging Name" v-model="packaging.name" rounded)
              div(class="field is-grouped is-grouped-centered")
                p(class="control")
                  b-button(type="is-primary is-rounded" @click="incrementStep" :disabled="!isNameComplete") 
                    strong Next

        div(v-if="step === 2")
          div(class="columns is-centered")
            div(class="column is-half")
              h1(class="title") What is the price?
              div(class="columns" v-for="price in packaging.prices" position="is-centered")
                div(class="column")
                  b-field(label="PRICE")
                    b-input(placeholder="Enter Price" icon="dollar-sign" v-model="price.price" rounded)
                div(class="column")
                  b-field(label="QUANTITY")
                    b-input(placeholder="Enter Quantity" v-model="price.quantity" rounded)
                div(class="column")
                  b-field(label="UOM")
                    b-select(placeholder="Select UOM" v-model="price.unit" expanded rounded)
                      option(v-for="unit in packagingUnits") {{ unit.name }}
              div(class="field is-grouped is-grouped-centered")
                p(class="control")
                  b-button(type="is-primary-light is-rounded" @click="decrementStep") 
                    strong Back
                p(class="control")
                  b-button(type="is-primary is-rounded" @click="incrementStep" :disabled="!isPriceComplete") 
                    strong Next

        div(v-if="step === 3")
          div(class="columns is-centered")
            div(class="column is-half")
              h1(class="title") Select all product lines assciated with this packaging
              b-field(class= "has-text-left" v-for="productLine in productLines" :key="productLine.id")
                b-checkbox(v-model="packaging.productLines" :native-value="productLine.id") {{ productLine.name }}
              div(class="field is-grouped is-grouped-centered")
                p(class="control")
                  b-button(type="is-primary-light is-rounded" @click="decrementStep") 
                    strong Back
                p(class="control")
                  b-button(type="is-primary is-rounded" @click="incrementStep") 
                    strong Next

        div(v-if="step === 4")
          div(class="columns is-centered")
            div(class="column is-half")
              h1(class="title") Select sales channel containing this packaging
              b-field(label="SALES CHANNEL" position="is-centered")
                b-select(placeholder="Sales Channel" v-model="packaging.salesChannel" expanded rounded)
                  option(v-for="channel in salesChannel") {{ channel }}
              div(class="field is-grouped is-grouped-centered")
                p(class="control")
                  b-button(type="is-primary-light is-rounded" @click="decrementStep") 
                    strong Back
                p(class="control")
                  b-button(type="is-primary is-rounded" @click="incrementStep") 
                    strong Next

        div(v-if="step === 5")
          div(class="columns is-centered")
            div(class="column is-half")
              h1(class="title") Select each pack level containing this packaging
              b-field(class= "has-text-left" v-for="packLevel in packLevels" :key="packLevel.name" )
                b-checkbox(v-model="packaging.packLevels" :native-value="packLevel.name") {{ packLevel.name }} ({{ packLevel.description }})
              div(class="field is-grouped is-grouped-centered")
                p(class="control")
                  b-button(type="is-primary-light is-rounded" @click="decrementStep") 
                    strong Back
                p(class="control")
                  b-button(type="is-primary is-rounded" @click="incrementStep") 
                    strong Next

        div(v-if="step === 6")
          div(class="columns is-centered")
            div(class="column is-half")
              h1(class="title") Select your vendor
              b-field(label="VENDOR" position="is-centered" grouped)
                b-select(placeholder="Enter Vendor" v-model="packaging.vendor" expanded rounded)
                  option(v-for="vendor in vendors" :value="vendor.id" :key="vendor.id") {{ vendor.name }}
                b-button(type="is-primary is-rounded" @click="showVendorModal = true")
                  p(class="control")
                    strong Add New Vendor
              b-field(label="VENDOR PRODUCT CODE")
                b-input(placeholder="Enter Vendor Product Code" v-model="packaging.vendorProductCode" rounded)
          div(class="field is-grouped is-grouped-centered")
            p(class="control")
              b-button(type="is-primary-light is-rounded" @click="decrementStep") 
                strong Back
            p(class="control")
              b-button(type="is-primary is-rounded" @click="savePackaging" :disabled="!isVendorComplete") 
                strong Save
</template>

<script>
import packagingUnits from '../../constants/packagingUnits'
import ModifyVendor from '@/components/vendor/ModifyVendor.vue'
import firebase from 'firebase/app';
import 'firebase/firestore';
import { mapGetters } from "vuex";

const db = firebase.firestore();

export default {
  name: 'PackagingGuide',
  components: {
    ModifyVendor
  },
  props: {
    inputPackaging: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    isNameComplete() {
      return this.packaging.name
    },
    isPriceComplete() {
      return this.packaging.prices[0].price && 
        this.packaging.prices[0].quantity
    },
    isVendorComplete() {
      return this.packaging.vendor
    }
  },
  data() {
    return {
      packagingUnits: packagingUnits,
      showVendorModal: false,
      packaging: {
        name: '',
        vendorProductCode: '',
        prices: [
          {
            price: '',
            quantity: ''
          },
        ],
        productLines: [],
        packLevels: [],
        salesChannel: ''
      },
      productLines: [],
      vendors: [],
      salesChannel: ['Wholesale', 'Online / Retail', 'Both'],
      packLevels: [
        {name: 'Unit', description: 'i.e. bar, cookie'},
        {name: 'Pack', description: 'i.e. sleeve, pack sits on store shelf, or online pack'},
        {name: 'Master Case', description: 'i.e. pallet, distributor case'},
      ],
      step: 1
    }
  },
  mounted () {
    this.getVendors();
    this.getProductLines();
    this.initalizePackaging();
  },
  methods: {
    initalizePackaging() {
      if (this.inputPackaging != null) {
        this.packaging = { ...this.inputPackaging }
      }
      if (this.packaging.productLines === undefined) {
        this.packaging.productLines = []
      }
      if (this.packaging.packLevels === undefined) {
        this.packaging.packLevels = []
      }
    },
    incrementStep() {
      this.step = this.step + 1
    },
    decrementStep() {
      this.step = this.step - 1
    },
    getVendors() {
      this.vendors = [];
      db.collection("vendors")
        .where("organizationId", "==", this.user.data.organizationId)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            let vendor = doc.data()
            vendor.id = doc.id
            this.vendors.push(vendor)
          })
        })
        .catch(() => {
        this.$buefy.toast.open({
        message: 'There was a problem getting vendors.',
        type: 'is-danger',
        duration: 5000});
      });
    },
    getProductLines() {
      db.collection("product-lines")
        .where("organizationId", "==", this.user.data.organizationId)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            let productLine = doc.data()
            productLine.id = doc.id
            this.productLines.push(productLine)
          })
        })
    },
    savePackaging() {
      this.packaging.organizationId = this.user.data.organizationId

      if(this.inputPackaging == null) {
        db.collection("packaging").add({
          ...this.packaging
      })
      .then(() => {
        this.$buefy.toast.open({
        message: 'Packaging Saved',
        type: 'is-success',
        duration: 5000});
        this.$router.replace({ name: "Packaging" });
      })
      .catch(() => {
        this.$buefy.toast.open({
        message: 'There was a problem saving the packaging.',
        type: 'is-danger',
        duration: 5000});
      });
      } else {
        db.collection("packaging").doc(this.packaging.id).set({
          ...this.packaging
        })
        .then(() => {
          this.$buefy.toast.open({
          message: 'Packaging Saved',
          type: 'is-success',
          duration: 5000});
          this.$router.replace({ name: "Packaging" });
        })
        .catch(() => {
          this.$buefy.toast.open({
          message: 'There was a problem saving the packaging.',
          type: 'is-danger',
          duration: 5000});
        });
      }
    }
  }
}
</script>

<style lang="scss">
.titleButton {
  padding: 25px
}
</style>