<template lang="pug">
	div
		h1(class="title") Enter business address
		h2(class="subtitle") i.e. legal business address
		div(class="columns is-centered")
			div(class="column is-half")
				b-field(label="Street Address")
					b-input(placeholder="Street Address" type="text" v-model="org.address.street" rounded)
				b-field(label="City")
					b-input(placeholder="City" type="text" v-model="org.address.city" rounded)
				b-field(label="State")
					b-select(placeholder="State" v-model="org.address.state" expanded rounded)
						option(v-for="state in states") {{ state.name }}
				b-field(label="Zip Code")
					b-input(placeholder="Zip Code" v-model="org.address.zipcode" rounded)
				h4(class="title is-4")
					strong Is this address also your ship to address?
				h6(class="subtitle") i.e. recieve mail, raw ingredients, supplies
				b-field
					b-radio(v-model="org.address.isShipTo" native-value='true') Yes
				b-field
					b-radio(v-model="org.address.isShipTo" native-value='false') No
				b-button(type="is-primary-light is-rounded" @click="backStep") 
					strong Back
				b-button(type="is-primary is-rounded" @click="nextStep") 
					strong Next
</template>

<script>
import states from '../../../constants/states';
import firebase from 'firebase/app';
import 'firebase/firestore';

const db = firebase.firestore();

export default {
  name: 'BusinessAddress',
  props: ['org', 'user'],
  data() {
    return {
      states: states,
    };
  },
  methods: {
    nextStep() {
      this.saveBusinessAddress();
      if (this.org.address.isShipTo === 'true') {
        this.$store.commit('SET_STEP', 'ein');
      } else {
        this.$store.commit('SET_STEP', 'shipToAddress');
      }
    },
    backStep() {
      this.$store.commit('SET_STEP', 'name');
    },
    saveBusinessAddress() {
      db.collection('organizations')
        .doc(this.user.data.organizationId)
        .update({
          address: this.org.address,
          shippingAddress: this.org.address.isShipTo
            ? {
                street: this.org.address.street,
                city: this.org.address.city,
                state: this.org.address.state,
                zipcode: this.org.address.zipcode,
                isCoMan: 'false',
              }
            : this.org.shippingAddress,
        })
        .then(() => {})
        .catch(() => {
          this.$buefy.toast.open({
            message: 'There was a problem saving the address.',
            type: 'is-danger',
            duration: 5000,
          });
        });
    },
  },
};
</script>
