<template lang="pug">
	div
		h1(class="title") The next step is to apply for you tax resale license (or exemption permit).
		h2(class="subtitle") This link will take you to the website your business is registered in.
		h3(class="subtitle is-6") Always make sure you read the local requirements for your state, and check with your accountant to ensure documents are filed correctly.
		div(class="columns is-centered")
			div(class="column is-half")
				div(class="block")
					a(class="button is-primary is-rounded is-medium" 
						:href="portals[org.address.state]"
						target="_blank" 
						rel="noopener noreferrer") Apply for your Tax Resale License
				b-button(type="is-primary-light is-rounded" @click="backStep") 
					strong Back
				b-button(type="is-primary is-rounded" @click="nextStep") 
					strong Next
</template>

<script>
import portals from '../../../constants/taxNumbers';

export default {
  name: 'BusinessTinLink',
  props: ['org'],
  data() {
    return {
      portals: portals,
    };
  },
  methods: {
    nextStep() {
      this.$store.commit('SET_STEP', 'mfgQuestion');
    },
    backStep() {
      this.$store.commit('SET_STEP', 'tin');
    },
  },
};
</script>
