<template lang="pug">
  div(class="mx-6")
    section(class="hero is-light")
      section(class="level")
        div(class="hero-body")
          h1(class="title") Products
        div(class="level-right")
          b-button(type="is-primary is-rounded" @click="$router.push('productLines')") 
            strong Manage Product Lines
          .titleButton
            b-button(type="is-primary is-rounded" @click="$router.push('manageProduct')") 
              strong Add Product
    br
    div(v-if="loading")
    div(v-else-if="!loading && !products.length")
      div(class="hero has-text-centered")
        div(class="hero-body")
          h1(class="title") No Products
          h1(class="subtitle") What are you waiting for? Go add some!
    div(v-else)
      b-table(class="box" :data='products' default-sort="name" sort-icon-size="is-small")
        b-table-column(field="name" label="Name" sortable v-slot="props" width="25%") {{ props.row.name }}
        b-table-column(field="action" cellClass="has-text-right" v-slot="props")
          b-button(type="is-info is-rounded ml-1" title="Edit" icon-left="eye" @click="$router.push({ name: 'Product', params: {product: props.row }})")
          b-button(type="is-primary is-rounded ml-1" title="Edit" icon-left="edit" @click="$router.push({ name: 'ManageProducts', params: {inputProduct: props.row }})")
          b-button(type="is-danger is-rounded ml-1" title="Delete" icon-left="trash" @click="deleteProductConfirmation(props.row)")
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';
import { mapGetters } from "vuex";

const db = firebase.firestore();

export default {
  name: 'Products',
  computed: {
    ...mapGetters({
      user: "user",
    })
  },
  watch: {
    'user': {
      handler (user) {
        if (user.data.organizationId) { 
          this.getProducts();
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      products: [],
      loading: true
    }
  },
  methods: {
    getProducts() {
      db.collection("products")
        .where("organizationId", "==", this.user.data.organizationId)
        .get()
        .then(querySnapshot => {
          this.products = []
          querySnapshot.forEach(doc => {
            let product = doc.data()
            product.id = doc.id
            this.products.push(product)
          })
          this.loading = false
        })
        .catch(() => {
        this.$buefy.toast.open({
        message: 'There was a problem getting products.',
        type: 'is-danger',
        duration: 5000});
        this.loading = false
      });
    },
    deleteProductConfirmation(product) {
      this.$buefy.dialog.confirm({
        title: 'Deleting Product',
        message: 'Are you sure you want to <b>delete</b> ' + product.name + "? This action cannot be undone.",
        confirmText: 'Delete Product',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteProduct(product)
      })
    },
    deleteProduct(product) {
      db.collection("products")
        .doc(product.id)
        .delete()
        .then(() => {
          this.products = this.products.filter(item => item !== product)
          this.$buefy.toast.open({
            message: 'Product Deleted',
            type: 'is-success',
            duration: 5000});
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'There was a problem deleting the product.',
          type: 'is-danger',
          duration: 5000});
      });
    }
  }
}
</script>

<style lang="scss">
.titleButton {
  padding: 25px
}
</style>