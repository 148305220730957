<template lang="pug">
	div
		h1(class="title") Do you currently have inventory stored?
		h2(class="subtitle") i.e. finished inventory, raw ingredients, packaging supplies stored
		div(class="columns is-centered")
			div(class="column is-half")
				b-field
					b-radio(v-model="inventoryStored" name="name" native-value='true') Yes
				b-field
					b-radio(v-model="inventoryStored" name="name" native-value='false') No
				b-button(type="is-primary-light is-rounded" @click="backStep") 
					strong Back
				b-button(type="is-primary is-rounded" @click="nextStep") 
					strong Next
</template>

<script>
export default {
  name: 'InventoryExists',
  props: ['stepComplete'],
  data() {
    return {
      inventoryStored: 'true',
    };
  },
  methods: {
    nextStep() {
      if (this.inventoryStored === 'true') {
        this.$store.commit('SET_STEP', 'storageType');
      } else {
        this.stepComplete();
      }
    },
    backStep() {},
  },
};
</script>
