<template lang="pug">
  section
    div(class="container")
      h1(class="title" class="has-text-centered") Product: {{product.name}}
      div(class="columns is-centered")
        div(class="column is-half")
          div(class="box")
            p(class="title is-5") Recipe Summary
            b-table(:data="recipe")
              b-table-column(field="name" label="Name" v-slot="props")
                p {{ props.row.name }}
              b-table-column(field="quantity" label="Quantity" v-slot="props")
                p {{ props.row.quantity }} {{props.row.unit}}s
              b-table-column(field="cost" label="Cost" v-slot="props")
                p ${{ props.row.cost }}
        div(class="column is-half")
          div(class="box")
            p(class="title is-5") Cost Breakdown
              apexchart(type="donut" :options="chartOptions" :series="ingredientPrices")
</template>

<script>
import ingredientUnits from '../../constants/ingredientUnits'
import firebase from 'firebase/app';
import 'firebase/firestore';
import { mapGetters } from "vuex";

const db = firebase.firestore();

export default {
  name: 'Product',
  props: {
    product: {
      type: Object
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    recipe() {
      let productIngredients = this.product.ingredients
      productIngredients.forEach((productIngredient) => {
        const ingredient = this.ingredients.find(ingredient => {
          return ingredient.id === productIngredient.id
        })
        productIngredient.name = ingredient ? ingredient.name : ""
        productIngredient.cost = ingredient ? this.calculateIngredientCost(productIngredient, ingredient) : ""
        if (productIngredient.name) {
          this.ingredientNames.push(productIngredient.name)
          let cost = parseFloat(productIngredient.cost)
          if (isNaN(cost)) {
            this.ingredientPrices.push(0)
          } else {
            this.ingredientPrices.push(parseFloat(productIngredient.cost))
          }
        }
        this.chartOptions.labels = this.ingredientNames
      })
      return productIngredients;
    },
  },
  async mounted () {
    await this.getIngredients();
  },
  data() {
    return {
      ingredientUnits: ingredientUnits,
      ingredients: [],
      ingredientNames: [],
      ingredientPrices: [],
      series: [44, 55, 41, 17, 15],
      chartOptions: {
        labels: [],
        chart: {
          type: 'donut',
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    }
  },
  methods: {
    async getIngredients() {
      const ingredientIds = this.product.ingredients.map(i => i.id);
      await db.collection("ingredients")
        .where("organizationId", "==", this.user.data.organizationId)
        .where(firebase.firestore.FieldPath.documentId(), "in", ingredientIds)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            let ingredient = doc.data()
            ingredient.id = doc.id
            this.ingredients.push(ingredient)
          })
        })
    },
    calculateIngredientCost(productIngredient, ingredient) {
      const productQuantity = Number(productIngredient.quantity)
      const productUnitName = productIngredient.unit
      const ingredientQuantity = Number(ingredient.prices[0].quantity)
      let ingredientUnitName = ingredient.prices[0].unit
      const ingredientPrice = Number(ingredient.prices[0].price)

      if (ingredientUnitName.includes("(")) {
        ingredientUnitName = ingredientUnitName.split('(')[0].replace(')', '').trim();
      }

      const productUnit = ingredientUnits.find(i => {
        return i.name == productUnitName
      })

      const ingredientUnit = ingredientUnits.find(i => {
        return i.name == ingredientUnitName
      })

      if(productUnit.type !== ingredientUnit.type) {
        return "Unknown"
      }

      const productQuantityConverted = productQuantity * productUnit.conversion
      const ingredientQuantityConverted = ingredientQuantity * ingredientUnit.conversion
      const ingredientCostConverted = ingredientPrice / ingredientQuantityConverted

      return (productQuantityConverted * ingredientCostConverted).toFixed(3)
    }

  }
}
</script>